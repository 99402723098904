<template>
	<div
		class="panel"
		:class="[
			isHeaderIndent ? 'mt-0 pt-1' : '',
			isFooterIndent ? 'mb-0 pb-1' : '',
			block.appearance ? block.appearance : ''
		]"
	>
		<div
			class="container"
			:class="[
				isBrand? 'sk' : '',
			]"
		>
			<div v-if="isHeaderVisible" class="panel_header">
				<h2>{{ block.block_title }}</h2>
				<div v-if="block.description" class="description" v-html="block.description" />
			</div>
			<div class="items-container">
				<div class="row">
					<div
						v-for="(item, index) in block.items"
						v-if="block.items"
						:key="index"
						class="col-12 pt-4 mb-4 border-top"
						:class="[
							block.columns ? 'col-lg-' + 12/block.columns : 'col-lg-4'
						]"
					>
						<div class="item flex-column d-flex justify-content-lg-between">
							<div v-if="item.title" class="title">
								{{ item.title }}
							</div>
							<div v-if="item.description" class="description" v-html="item.description" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';

export default {
	name: 'DraftWh04',
	mixins: [panel],
	props: ['block'],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/draft-whywe-4";
</style>
