var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel",class:[
		_vm.isHeaderIndent ? 'mt-0 pt-1' : '',
		_vm.isFooterIndent ? 'mb-0 pb-1' : '',
		_vm.block.appearance ? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container",class:[
			_vm.isBrand? 'sk' : '',
		]},[(_vm.isHeaderVisible)?_c('div',{staticClass:"panel_header"},[_c('h2',[_vm._v(_vm._s(_vm.block.block_title))]),_vm._v(" "),(_vm.block.description)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.block.description)}}):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"items-container"},[_c('div',{staticClass:"row"},_vm._l((_vm.block.items),function(item,index){return (_vm.block.items)?_c('div',{key:index,staticClass:"col-12 pt-4 mb-4 border-top",class:[
						_vm.block.columns ? 'col-lg-' + 12/_vm.block.columns : 'col-lg-4'
					]},[_c('div',{staticClass:"item flex-column d-flex justify-content-lg-between"},[(item.title)?_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(item.description)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e()])]):_vm._e()}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }